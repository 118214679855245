import React from 'react';
import logo from './logo.svg';
import './App.css';
import styled from "styled-components";


const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Logo = styled.img`
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const GoogleSignInButton = styled.button`
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #357ae8;
  }

  @media (max-width: 768px) {
    padding: 10px 15px;
    font-size: 14px;
  }
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  color: #666;
`;

function App() {
  const handleSignIn = () => {
    // Implement your sign-in logic here
    console.log('Sign in with Google');
  };

  return (
    <LoginContainer>
      <Logo src="Tosa___1_-removebg-preview.webp" alt="Backoffice Logo" />
      <GoogleSignInButton onClick={handleSignIn}>
        Sign in with Google
      </GoogleSignInButton>
      <Footer>
        Terms of Service | Privacy Policy
      </Footer>
    </LoginContainer>
  );
}

export default App;
